"use client";

import { motion } from "framer-motion";
import Eden from "@/components/molecules/Eden/Eden";
import { urlForImage } from "@/sanity/lib/image";
import { cn } from "@/lib/utils";
import gsap from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
type Props = {
  data: any;
  index?: number;
  lastIndex?: number;
};

const HeaderReduced = (props: Props) => {
  const { data, index, lastIndex } = props;
  gsap.registerPlugin(ScrollTrigger);
  let imageExists = data.image ? `` : `bg-[url('/header-main-bg.jpg')] `;
  const divProps = {
    style: data.image && {
      backgroundImage: `url(${urlForImage(data.image).format("webp").url()})`,
    },
    className: `px-4 py-6 sm:p-8 md:p-16 bg-cover ${imageExists} ${
      index === lastIndex && "rounded-b-none sm:rounded-2xl"
    } ${
      index === 0 && "rounded-t-none sm:rounded-2xl"
    } rounded-2xl relative overflow-hidden`,
  };
  const ref = useRef(null);
  useGSAP(
    () => {
      const tl = gsap.timeline({
        delay: 0.8,
        scrollTrigger: {
          trigger: ".eden-text",
        },
      });

      tl.fromTo(
        ".eden-text",
        { scale: 0, opacity: 0, transformOrigin: "bottom left" },
        { scale: 1, opacity: 1, ease: "power4.out" }
      ).fromTo(
        ".eden-subtext",
        { opacity: 0, transformOrigin: "top left" },
        { opacity: 1, ease: "power4.out" },
        ">0.2"
      );
    },
    { scope: ref }
  );
  return (
    <div
      ref={ref}
      className={cn({
        "bg-[#f5f5f6]": data.background === "light",
      })}
    >
      <section className="pb-4 sm:container sm:py-6 ">
        <div
          // style={
          //   data.image && {
          //     backgroundImage: `url(${urlForImage(data.image)
          //       .format("webp")
          //       .url()})`,
          //   }
          // }
          {...divProps}
        >
          <div className="w-full z-[1] h-full absolute bg-black/30 left-0 top-0"></div>
          <motion.div
            layoutId="home"
            className="flex relative z-[2] flex-col sm:flex-row gap-2 sm:gap-4 mb-4"
          >
            <div
              className={`w-full max-w-[66px] sm:max-w-[80px] md:max-w-[125px] h-fit aspect-square`}
            >
              <Eden />
            </div>
            <motion.div
              transition={{ duration: 0.5 }}
              className="rounded-tl-[14.5px] eden-text sm:bg-white flex items-center sm:justify-center rounded-tr-[14.5px] rounded-br-[14.5px]"
            >
              <h1
                className={`text-[#0C1E20] py-4 sm:py-0 text-[38px] sm:text-[40px] md:text-[77px]  rounded-b-[14.5px] rounded-tr-[14.5px] bg-white sm:bg-transparent font-bold transition-all px-6`}
              >
                {data.edenText}
              </h1>
            </motion.div>
          </motion.div>

          {data.subtext && (
            <div className="hidden relative z-[2] sm:block eden-subtext max-w-[675px] mt-16 font-semibold text-[20px] md:text-[27px]">
              <h5>{data.subtext}</h5>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default HeaderReduced;
