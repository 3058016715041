"use client";

import ReactPlayer from "react-player/lazy";
import dynamic from "next/dynamic";
import { cn } from "@/lib/utils";
import { sectionBackgroundHandler } from "@/lib/globalFunctions";
import { VideoTextProps } from "@/types/TypeList";
const DynamicReactPlayer = dynamic(() => Promise.resolve(ReactPlayer), {
  ssr: false,
});
type Props = {
  data: VideoTextProps;
};

const VideoText = (props: Props) => {
  const { data } = props;

  return (
    <section className={cn(sectionBackgroundHandler(data.background))}>
      <div
        className={cn(
          `flex container flex-col sm:flex-row-reverse items-center py-10`,
          data.position === "left" && "sm:flex-row"
        )}
      >
        <div className="max-w-[384px] mr-auto">
          <p className="text-xl sm:text-h5 font-semibold">{data.caption}</p>
          <h3 className="text-[26px] sm:text-h3 font-semibold py-2 sm:py-4 leading-none">
            {data.heading}
          </h3>
          <p className="text-[18px] sm:text-subtitle py-2 sm:py-4">
            {data.description}
          </p>
        </div>
        <div className="flex-1 w-full sm:w-auto sm:pt-8 phone:pt-20 flex justify-center sm:px-8">
          <div className="max-w-[1046px] sm:mt-8 w-full mx-auto">
            <div className="player-wrapper w-full ">
              <DynamicReactPlayer
                className="react-player"
                url={data.url}
                width="100%"
                height="100%"
                controls={false}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoText;
