import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { HTMLAttributes } from "react";
const SkeletonCard: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Card className={cn(`w-[378px] shadow-none border-0`, props)}>
      <CardContent className="pt-6">
        <Skeleton className="bg-black/25 h-4 w-full my-2 " />
        <Skeleton className="bg-black/25 h-4 w-full my-2" />
        <Skeleton className="bg-black/25 h-36 w-full my-2" />
      </CardContent>
    </Card>
  );
};

export default SkeletonCard;
