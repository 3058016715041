import { sectionBackgroundHandler } from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import dynamic from "next/dynamic";
import React from "react";
import ReactPlayer from "react-player";

type Props = {
  data: {
    video: string;
    heading: string;
  };
};
const DynamicReactPlayer = dynamic(() => Promise.resolve(ReactPlayer), {
  ssr: false,
});
const VideoSection = (props: Props) => {
  return (
    <section>
      <div className="mx-auto px-4">
        <h3
          style={{ paddingBottom: 0 }}
          className="text-center text-[26px]  sm:text-h3 font-semibold py-2 sm:py-4 leading-none"
        >
          {props.data.heading}
        </h3>
      </div>
      <div className="flex-1 px-4 w-full sm:w-auto phone:pt-20 flex justify-center sm:px-8">
        <div className="max-w-[1046px] sm:mt-8 w-full mx-auto">
          <div className="player-wrapper w-full ">
            <DynamicReactPlayer
              className="react-player"
              url={props.data.video}
              width="100%"
              height="100%"
              controls={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
