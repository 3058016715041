"use client";
import { useEffect, useState } from "react";
import { GET_ALL_PRODUCT } from "@/sanity/actions";
import ProductCard from "../Card/ProductCard";
import { Skeleton } from "@/components/ui/skeleton";
import SkeletonCard from "../SkeletonVariants/SkeletonCard";
import { Button } from "@/components/ui/button";

export function CardListProducts() {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    async function fetchArticles() {
      let response = await GET_ALL_PRODUCT();
      setData(response);
    }

    fetchArticles();
  }, []);
  const [elementIndex, setElementIndex] = useState(12);
  let sliced = data.slice(0, elementIndex);
  const loadMore = () => {
    setElementIndex(elementIndex + elementIndex);
  };
  if (data.length === 0) {
    return (
      <div className="bg-[#f5f5f6]">
        <div className="container py-8 transition-all grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="bg-[#f5f5f6]">
      <div className="container  py-8">
        <div className=" transition-all grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {data &&
            sliced.map((item: any, index: number) => {
              return <ProductCard data={item} key={index} />;
            })}
        </div>
      </div>
      {elementIndex < data.length && (
        <div className="flex justify-center">
          <Button onClick={loadMore} variant={"secondary"}>
            Show More
          </Button>
        </div>
      )}
    </div>
  );
}
export default CardListProducts;
