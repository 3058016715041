"use client";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { FaCheckCircle } from "react-icons/fa";
import { GET_ALL_POST } from "@/sanity/actions";
import { learnCategory } from "@/lib/globalFunctions";
import SkeletonCard from "../SkeletonVariants/SkeletonCard";
import ArticleCard from "../Card/ArticleCard";
import { AnimatePresence, motion } from "framer-motion";
export function CardListArticles() {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    async function fetchArticles() {
      let response = await GET_ALL_POST();
      setData(response);
    }

    fetchArticles();
  }, []);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const handleCategoryClick = (value: string) => {
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter((val) => val !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };
  if (data.length === 0) {
    return (
      <div className="bg-[#f5f5f6]">
        <div className="container py-8 transition-all grid grid-cols-3 gap-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="bg-[#f5f5f6]">
      <div className="container  flex py-4 sm:pb-4 items-center">
        <small className="text-black/50">
          {data.length} of {data.length} showing
        </small>
        {/* <div className="hidden sm:flex gap-4">
          {learnCategory.map((item, index) => {
            return (
              <Button
                variant={"primary"}
                size="primary"
                key={index}
                className={cn(
                  "bg-transparent relative transition-all hover:bg-main-accent/50 border px-12 border-black",
                  {
                    "bg-main-accent": selectedCategories.includes(item.value),
                  }
                )}
                onClick={() => handleCategoryClick(item.value)}
              >
                <FaCheckCircle
                  className={cn(
                    " absolute left-6  transition-all",
                    selectedCategories.includes(item.value)
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />

                {item.title}
              </Button>
            );
          })}
        </div>
        <div className="hidden sm:block ml-auto">
          <Select>
            <SelectTrigger
              className={cn(
                "inline-flex items-center justify-center  text-sm  ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 font-semibold text-main-dark-green border border-main-dark-green rounded-full px-4 py-6"
              )}
            >
              <SelectValue placeholder="Most Recent" />
            </SelectTrigger>
            <SelectContent className="">
              <SelectGroup>
                <SelectItem value="most-recent">Most Recent</SelectItem>
                <SelectItem value="Popular">Popular</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div> */}
      </div>
      <div className="container py-8 grid md:grid-cols-3 gap-6">
        <AnimatePresence>
          {data &&
            data
              .filter((item: any) =>
                selectedCategories.length === 0
                  ? true
                  : selectedCategories.includes(item.postCategory)
              )
              .map((item: any, index: number) => {
                return (
                  <motion.div
                    layout
                    key={index}
                    initial={{ opacity: 0, x: 0 }}
                    exit={{ opacity: 0, x: 0 }}
                    animate={{ opacity: 1, x: 0 }}
                  >
                    <ArticleCard data={item} key={index} />
                  </motion.div>
                );
              })}
        </AnimatePresence>
      </div>
    </div>
  );
}
export default CardListArticles;
