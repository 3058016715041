"use client";
import { FC } from "react";
// import { PortableText } from "@portabletext/react";
import CardList from "@/components/organisms/CardList";
import Header from "@/components/organisms/Header";
import VideoText from "@/components/molecules/VideoText/VideoText";
import FileDownload from "@/components/molecules/FileDownload/FileDownload";
import MultiColumn from "./molecules/MultiColumn/MultiColumn";
import TextSection from "./molecules/TextSection/TextSection";
import TextImage from "./molecules/TextImage/TextImage";
import ProductFeature from "./molecules/ProductFeature/ProductFeature";
import CompanyShowcase from "./molecules/CompanyShowcase/CompanyShowcase";
import ShortCTA from "./molecules/ShortCTA/ShortCTA";
import FeaturedCta from "./molecules/FeaturedCTA/FeaturedCTA";
import Transition from "@/app/transition";
import FeaturedImageText from "./molecules/FeaturedImageText/FeaturedImageText";
import EdenTitle from "./molecules/EdenTitle/EdenTitle";
import StickyFeaturedScroll from "./molecules/StickyFeaturedScroll/StickyFeaturedScroll";
import EdenScrollParagraph from "./molecules/EdenScrollParagraph/EdenScrollParagraph";
import GridPost from "./molecules/GridPost/GridPost";
import ShopByCategory from "./molecules/ShopByCategory/ShopByCategory";
import AccordionSection from "./molecules/AccordionSection/AccordionSection";
import RotatingSection from "./organisms/RotatingSection/RotatingSection";
import TextImageWithOverlay from "./molecules/TextImageWithOverlay/TextImageWithOverlay";
import ValuesSection from "./molecules/ValuesSection/ValuesSection";
import StickyTextImage from "./molecules/StickyTextImage/StickyTextImage";
import CarouselHeader from "./molecules/CarouselHeader/CarouselHeader";
import VideoSection from "./molecules/VideoSection/VideoSection";
interface IPageBuilder {
  data: any;
}

const PageBuilder: FC<IPageBuilder> = (props: IPageBuilder) => {
  const { data } = props;
  if (!data) {
    return null;
  }

  return data.map((item: any, key: number) => {
    // item.schemaType = data;
    switch (item._type) {
      case "header":
        return (
          <Transition>
            <Header
              index={key}
              lastIndex={data.length - 1}
              key={key}
              data={item}
            />
          </Transition>
        );
      case "cardList":
        return (
          <Transition>
            <CardList key={key} data={item} />
          </Transition>
        );
      case "videoText":
        return (
          <Transition>
            <VideoText key={key} data={item} />
          </Transition>
        );
      case "fileDownload":
        return (
          <Transition>
            <FileDownload key={key} data={item} />
          </Transition>
        );
      case "multiColumn":
        return (
          <Transition>
            <MultiColumn data={item} key={key} />
          </Transition>
        );
      case "textSection":
        return (
          <Transition>
            <TextSection data={item} key={key} />
          </Transition>
        );
      case "textImage":
        return (
          <Transition>
            <TextImage data={item} key={key} />
          </Transition>
        );
      case "productFeature":
        return (
          <Transition>
            <ProductFeature data={item} key={key} />
          </Transition>
        );
      case "companyShowcase":
        return (
          <Transition>
            <CompanyShowcase data={item} key={key} />
          </Transition>
        );
      case "shortCta":
        return (
          <Transition>
            <ShortCTA data={item} key={key} />
          </Transition>
        );
      case "featuredCta":
        return (
          <Transition>
            <FeaturedCta data={item} key={key} />
          </Transition>
        );
      case "featuredImageText":
        return (
          <Transition>
            <FeaturedImageText data={item} key={key} />
          </Transition>
        );
      case "edenTitle":
        return (
          <Transition>
            <EdenTitle data={item} key={key} />
          </Transition>
        );
      case "stickyFeaturedScroll":
        return (
          <Transition>
            <StickyFeaturedScroll key={key} />
          </Transition>
        );
      case "edenScrollParagraph":
        return (
          <Transition>
            <EdenScrollParagraph data={item} key={key} />
          </Transition>
        );
      case "gridPost":
        return (
          <Transition>
            <GridPost data={item} key={key} />
          </Transition>
        );
      case "shopByCategory":
        return (
          <Transition>
            <ShopByCategory data={item} key={key} />
          </Transition>
        );
      case "accordionSection":
        return (
          <Transition>
            <AccordionSection data={item} key={key} />
          </Transition>
        );
      case "rotatingSection":
        return (
          <Transition>
            <RotatingSection key={key} />
          </Transition>
        );
      case "textImageWithOverlay":
        return (
          <Transition>
            <TextImageWithOverlay data={item} key={key} />
          </Transition>
        );
      case "valuesSection":
        return (
          <Transition>
            <ValuesSection key={key} />
          </Transition>
        );
      case "stickyTextImage":
        return (
          <Transition>
            <StickyTextImage data={item} key={key} />
          </Transition>
        );
      case "carouselHeader":
        return (
          <Transition>
            <CarouselHeader data={item} key={key} />
          </Transition>
        );
      case "videoSection":
        return (
          <Transition>
            <VideoSection data={item} key={key} />
          </Transition>
        );
      default:
        return (
          <Transition>
            <div key={key + Math.random()} className="bg-red-100 py-16">
              <div className="container mx-auto">
                <h1 className="text-3xl text-black text-center">
                  No component for {item._type}
                </h1>
              </div>
            </div>
          </Transition>
        );
    }
  });
};

export default PageBuilder;
