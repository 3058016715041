import Word from "@/components/atoms/Word";
import { Button } from "@/components/ui/button";
import {
  portableTextComponents,
  portableTextComponentsAnimated,
} from "@/lib/globalFunctions";
import { urlForImage } from "@/sanity/lib/image";
import { PortableText } from "next-sanity";
import Image from "next/image";
import React, { useState } from "react";
import { usePathname } from "next/navigation";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
type Props = {
  data: any;
};

const StickyTextImage = (props: Props) => {
  const { data } = props;
  const [isOpen, setOpen] = useState(false);
  const pathname = usePathname();
  return (
    <div className="bg-[#0C1E20]">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        allowFullScreen={true}
        videoId="Cw6YCEHp8uU"
        onClose={() => setOpen(false)}
      />
      <div className="container mb-16">
        <h2 className="text-h4 md:text-h2 text-center font-bold pb-12 md:pb-[120px]">
          {data.heading}
        </h2>
        <div className="flex gap-24 flex-col-reverse md:flex-row">
          <div className="flex-1 py-4 md:my-32 flex flex-col gap-16 md:gap-24">
            {data.contentList &&
              data.contentList.length > 0 &&
              data.contentList.map((content: any, index: number) => {
                return (
                  <div key={index}>
                    <PortableText
                      value={content.content}
                      components={portableTextComponentsAnimated}
                    />
                    {content.addButton && (
                      <>
                        {content.button?.type === "getStarted" ? null : (
                          <Button
                            className="relative z-[2] mt-4"
                            size={"lg"}
                            onClick={() => {
                              if (content?.button?.label.includes("keynote")) {
                                setOpen(true);
                              }
                            }}
                            variant={content?.button?.variant}
                          >
                            {content?.button?.label}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="flex-1 relative">
            <Image
              src={urlForImage(data.image).format("webp").url()}
              alt={data.title}
              width={627}
              height={627}
              className="sticky top-8 rounded-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyTextImage;
