import CardListArticles from "../molecules/CardListVariants/CardListArticles";
import CardListProducts from "../molecules/CardListVariants/CardListProducts";
import CardListCustomProducts from "../molecules/CardListVariants/CardListCustomProducts";
import CardListCustomArticles from "../molecules/CardListVariants/CardListCustomArticles";
import CardListEden from "../molecules/CardListVariants/CardListEden";

type Props = {
  data: any;
};

const CardList = (props: Props) => {
  const { data } = props;
  switch (data.listType) {
    case "articleList":
      return <CardListArticles />;
    case "productList":
      return <CardListProducts />;
    case "customProductList":
      return <CardListCustomProducts data={data} />;
    case "customArticleList":
      return <CardListCustomArticles data={data} />;
    case "edenCardList":
      return <CardListEden data={data} />;
    default:
      return (
        <div className="bg-red-100 py-16 container">
          CardList.tsx no listType handler for "{data.listType}"
        </div>
      );
  }
};

export default CardList;
