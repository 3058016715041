"use client";
import React from "react";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { Button } from "@/components/ui/button";
import { useGlobalStore } from "@/lib/globalStore";
type Props = {
  icon: any;
  text: string;
  title: string;
  isLight?: boolean;
  data: any;
};

const MultiColumnCard = (props: Props) => {
  const { icon, text, title, isLight, data } = props;
  const { setGetStartedOpen } = useGlobalStore();
  const phoneNumberRegex = /(\d{4}[\s-]?\d{3}[\s-]?\d{3})/;
  // Regular expression to match the email pattern
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  // Extracting the phone number from the text
  let phoneNumberMatch: any;
  if (text) {
    phoneNumberMatch = text.match(phoneNumberRegex);
  }

  const renderedText = () => {
    return phoneNumberMatch
      ? text.split(phoneNumberMatch[0]).map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index !== text.split(phoneNumberMatch[0]).length - 1 && (
              <a
                href={`tel:${phoneNumberMatch[0]}`}
                className="text-main-dark-green"
              >
                <br />
                <span className="text-main-dark-green underline">
                  {phoneNumberMatch[0]}
                </span>
              </a>
            )}
          </React.Fragment>
        ))
      : text.split(" ").map((word, index) => {
          if (word.match(phoneNumberRegex)) {
            const phoneNumber = word.replace(/\s/g, ""); // Remove any whitespaces
            return (
              <a
                href={`tel:${phoneNumber}`}
                className="text-main-dark-green underline"
                key={index}
              >
                {word}
              </a>
            );
          } else if (word.match(emailRegex)) {
            return (
              <>
                <br />
                <a
                  href={`mailto:${word}`}
                  className="text-main-dark-green underline"
                  style={{ wordBreak: "break-word" }}
                  key={index}
                >
                  {word}
                </a>
              </>
            );
          } else {
            return word + " ";
          }
        });
  };
  return (
    <div className="py-4 sm:py-0">
      <div className="w-[60px] h-[60px] bg-main-accent rounded-full p-3 mx-auto">
        {icon && (
          <Icon icon={icon?.name} className="w-full h-full text-black" />
        )}
      </div>
      <h4
        className={cn(
          "text-[22px] sm:text-[28px]  md:text-h4 py-4 font-semibold text-center",
          isLight ? "text-main-dark-green" : ""
        )}
      >
        {title}
      </h4>
      {text && (
        <p
          className={cn(
            "text-[18px] max-w-[358px] mx-auto sm:max-w-none sm:text-[18px] md:text-subtitle text-center",
            isLight ? "text-main-dark-green" : ""
          )}
        >
          {renderedText()}
        </p>
      )}
      {data?.addButton && (
        <div className="flex justify-center my-4">
          {data.button.type === "getStarted" && (
            <Button
              onClick={() => {
                if (data.button.type === "getStarted") {
                  return setGetStartedOpen(true);
                }
              }}
              variant={data.button.variant}
            >
              {data.button.label}
            </Button>
          )}

          {data.button.href === "call-us" && (
            <a
              href={`tel:${phoneNumberMatch[0]}`}
              className="text-main-dark-green"
            >
              <Button variant={data.button.variant}>{data.button.label}</Button>
            </a>
          )}
          {data.button.href === "email-us" && (
            <a
              href={`mailto:sales@enviroplusproducts.com.au`}
              className="text-main-dark-green"
            >
              <Button variant={data.button.variant}>{data.button.label}</Button>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiColumnCard;
