import { Button } from "@/components/ui/button";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { useGlobalStore } from "@/lib/globalStore";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import Link from "next/link";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type Props = {
  data: any;
};

const FeaturedImageText = (props: Props) => {
  const { data } = props;
  const isOdd = (num: number) => num % 2;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  let element = useRef(null);
  const { setGetStartedOpen } = useGlobalStore();
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element.current,
        start: "top center",
      },
    });
    tl.to(element.current, 1, { width: "100%", borderRadius: 0 });
    // tl.to(".corner", 1, { borderRadius: 0 });
  }, [{ scope: [element] }]);
  return (
    <section
      className={cn(
        sectionBackground,
        data.isCapsulated ? "pt-[45px]" : sectionSpacing
      )}
    >
      {/* <ParallaxComponent /> */}
      <div
        ref={element}
        className={cn(
          data.isCapsulated
            ? "bg-[#f5f5f6] rounded-3xl w-fit mx-auto md:py-[80px]"
            : ""
        )}
      >
        <div className={cn("sm:container")}>
          <div
            className={cn(
              " flex corner sm:gap-12  flex-col rounded-3xl text-black ",
              data.isDark ? "bg-main-dark-green py-[120px] text-white" : "",
              data.isCapsulated ? "bg-[#f5f5f6] py-[80px]" : ""
            )}
          >
            {data.heading && (
              <div
                className={cn(
                  `max-w-[896px] mx-auto flex flex-col justify-center items-center gap-4 mb-12`,
                  data.isDark
                    ? "bg-main-dark-green  text-white"
                    : "text-main-dark-green",
                  data.isCapsulated ? "bg-[#f5f5f6] text-main-dark-green" : ""
                )}
              >
                {data.heading && (
                  <h2 className="text-[38px] sm:text-h1 font-semibold text-center">
                    {data.heading}
                  </h2>
                )}
                {data.subheading && (
                  <p className="text-[1.4rem] sm:text-h5 font-semibold text-center">
                    {data.subheading}
                  </p>
                )}
              </div>
            )}

            {data.featureList.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={cn(
                    ` flex gap-4 lg:px-[100px] py-6`,
                    !isOdd(index)
                      ? "flex-col md:flex-row-reverse"
                      : "flex-col md:flex-row"
                  )}
                >
                  <div
                    className={cn(
                      `flex-1 flex justify-center`,
                      data.isCapsulated ? "md:max-w-[380px]" : ""
                    )}
                  >
                    <div className="relative w-full max-w-[200px] sm:max-w-[250px] md:max-w-[380px] aspect-square ">
                      <Image
                        src={urlForImage(item.image).format("webp").url()}
                        alt={item.title}
                        layout="fill"
                        objectFit="contain"
                        className="rounded-[16px]"
                      />
                    </div>
                  </div>
                  <div
                    className={cn(
                      `text-main-dark-green w-full flex-1 ${item.image} px-4 justify-center flex flex-col`
                    )}
                  >
                    <div className="flex gap-2 md:gap-4">
                      <div className="w-[50px] md:w-full min-w-[50px] max-w-[50px] sm:max-w-[70px] md:max-w-[96px] max-h-fit aspect-square ">
                        <div
                          className={cn(
                            "w-[50px] md:w-full aspect-square rounded-2xl rounded-br-none bg-main-dark-green text-white flex items-center justify-center",
                            data.isDark
                              ? "bg-main-accent text-main-dark-green"
                              : "",
                            data.isCapsulated
                              ? "bg-main-dark-green text-white"
                              : ""
                          )}
                        >
                          <p className="text-[24px] md:text-h3 font-semibold">
                            {index + 1}
                          </p>
                        </div>
                      </div>

                      <h3
                        className={cn(
                          `text-[26px] sm:text-[40px] md:text-h3 px-3 py-2 md:py-4 md:px-6 rounded-tl-[8px] md:rounded-tl-3xl rounded-bl-none w-fit sm:rounded-bl-none rounded-[8px] md:rounded-3xl bg-white inline-block mr-auto font-semibold leading-tight`,
                          data.isCapsulated ? " text-main-dark-green" : ""
                        )}
                      >
                        {item.title}
                      </h3>
                    </div>
                    <div>
                      <p
                        className={cn(
                          data.isDark ? "text-white" : "",
                          item.textSize === "small"
                            ? "text-[26px] leading-normal font-semibold py-6"
                            : item.textSize === "large"
                            ? "text-xl sm:text-[28px] leading-tight md:text-h4  font-semibold py-6"
                            : "text-xl sm:text-[28px] md:text-h5 leading-normal font-semibold py-6",
                          data.isCapsulated ? "text-main-dark-green" : ""
                        )}
                      >
                        {item.description}
                      </p>
                    </div>

                    {item.addButton && (
                      <>
                        {item.button.type === "getStarted" ? (
                          <div>
                            <Button
                              size={"lg"}
                              variant={item.button.variant}
                              onClick={() => setGetStartedOpen(true)}
                            >
                              {item?.button?.label}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Link href={item.button.href}>
                              <Button
                                size={"lg"}
                                variant={item.button.variant}
                                // className=" rounded-full !text-subtitle border-2 border-black text-black font-semibold"
                              >
                                {item.button.label}
                              </Button>
                            </Link>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
            {data.addButton && (
              <>
                {data.button.type === "getStarted" ? (
                  <Button
                    size={"lg"}
                    variant={data.button.variant}
                    onClick={() => setGetStartedOpen(true)}
                  >
                    {data?.button?.label}
                  </Button>
                ) : (
                  <div className="flex items-center justify-center">
                    <Link href={data.button.href}>
                      <Button size={"lg"} variant={data.button.variant}>
                        {data.button.label}
                      </Button>
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedImageText;
