"use client";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FeaturedContent from "./FeaturedContent";
import { GoDotFill } from "react-icons/go";
let contentList = [
  {
    id: 1,
    title: "Environmentally safer",
    description:
      "Reduce your environmental impact with plant-based ingredients from renewable resources.",
    buttonText: "Clean greener",
    image: "/header-main-bg.jpg",
    url: "/environmental-impact",
  },
  {
    id: 2,
    title: "Healthier at work",
    description:
      "Create safer and healthier environments for your people by cleaning with fewer toxic chemicals.",
    buttonText: "Clean healthier",
    image: "/sticky3.png",
    url: "/healthy-workplaces",
  },
  {
    id: 3,
    title: "Better cleaning",
    description:
      "Clean more effectively with greener cleaning solutions that rival traditional products.",
    buttonText: "Clean better",
    image: "/sticky2.png",
    url: "/effective-cleaning",
  },
];

const SectionFull = (props: any) => {
  return (
    <div
      style={{
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={` w-full min-h-screen  ${props.className}`}
    ></div>
  );
};
export default function StickyFeaturedScroll() {
  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const boxContainer = useRef(null);
  useGSAP(() => {
    gsap.to(".box-1", {
      scrollTrigger: {
        trigger: ".content-1",
        start: "bottom bottom",
        end: "bottom top",
        scrub: true,
      },
      y: -window.innerHeight,
    });

    gsap.to(".section-text-1", {
      scrollTrigger: {
        trigger: ".content-1",
        start: "top 50%",
        end: "bottom 50%",
        onEnter: () => {
          gsap.to(".section-text-1", {
            color: "#31eb97", // Change the color to green when entering
          });
        },
        onLeave: () => {
          gsap.to(".section-text-1", {
            color: "white", // Change the color to white when leaving
          });
        },
        onEnterBack: () => {
          gsap.to(".section-text-1", {
            color: "#31eb97", // Change the color back to green when entering from the bottom
          });
        },
      },
    });
    gsap.to(".section-text-2", {
      scrollTrigger: {
        trigger: ".content-2",
        start: "top 50%",
        end: "bottom 50%",
        onEnter: () => {
          gsap.to(".section-text-2", {
            color: "#31eb97", // Change the color to green when entering
          });
        },
        onLeave: () => {
          gsap.to(".section-text-2", {
            color: "white", // Change the color to white when leaving
          });
        },
        onLeaveBack: () => {
          gsap.to(".section-text-2", {
            color: "white", // Change the color to white when leaving
          });
        },
        onEnterBack: () => {
          gsap.to(".section-text-2", {
            color: "#31eb97", // Change the color back to green when entering from the bottom
          });
        },
      },
    });
    gsap.to(".section-text-3", {
      scrollTrigger: {
        trigger: ".content-3",
        start: "top 50%",
        end: "bottom 50%",
        onEnter: () => {
          gsap.to(".section-text-3", {
            color: "#31eb97", // Change the color to green when entering
          });
        },
        onLeave: () => {
          gsap.to(".section-text-3", {
            color: "white", // Change the color to white when leaving
          });
        },
        onLeaveBack: () => {
          gsap.to(".section-text-3", {
            color: "white", // Change the color to white when leaving
          });
        },
        onEnterBack: () => {
          gsap.to(".section-text-3", {
            color: "#31eb97", // Change the color back to green when entering from the bottom
          });
        },
      },
    });

    gsap.to(".box-2", {
      scrollTrigger: {
        trigger: ".content-2",
        start: "bottom bottom",
        end: "bottom top",
        scrub: true,
      },
      y: -window.innerHeight,
    });
  }),
    [{ scope: boxContainer }];
  return (
    <div className="pb-10 bg-[#f5f5f6]">
      <main
        ref={boxContainer}
        className="bg-main-dark-green flex min-h-[300vh] relative contain w-full item items-start"
      >
        <div style={{ contain: "paint" }} className="absolute flex w-full">
          <div className="w-full flex flex-col items-center justify-center">
            {contentList.map((item, key) => {
              return <FeaturedContent key={key} item={item} />;
            })}
          </div>
          <div className="hidden md:block w-full">
            <div className="sticky top-0">
              <div className="relative">
                <SectionFull
                  image="/header-main-bg.jpg"
                  className="absolute bg-slate-600 w-full h-full z-[4] box-1"
                />
                <SectionFull
                  image="/sticky3.png"
                  className="absolute bg-orange-500 z-[3] box-2"
                />
                <SectionFull
                  image="/sticky2.png"
                  className="absolute bg-orange-300 z-[2] box-3"
                />
              </div>
            </div>
          </div>
          <div className="hidden lg:block max-w-[200px] absolute left-0 min-h-full">
            <div className="sticky top-0 h-screen ">
              <ul
                style={{ writingMode: "vertical-lr" }}
                className=" rotate-[180deg] flex justify-center h-full ml-4 gap-6"
              >
                <li className="flex gap-4 items-center section-text-3">
                  <GoDotFill />
                  Better cleaning
                </li>
                <li className="flex gap-4 items-center section-text-2">
                  <GoDotFill />
                  Healthier workplaces
                </li>
                <li className="flex gap-4 items-center section-text-1">
                  <GoDotFill />
                  Environmentally safer
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
