import React from "react";
import MultiColumnCard from "../Card/MultiColumnCard";
import { Icon } from "@iconify/react";
import { cn } from "@/lib/utils";
type Props = {};

const ValuesSection = (props: Props) => {
  const data = [
    {
      id: 1,
      text: "We wholeheartedly believe in the future we envision and the possibilities that we can unlock when we put sustainable methodologies at our core.",
      title: "Practical optimism",
      icon: {
        name: "bxs:happy",
      },
      isLight: false,
      data: {},
    },
    {
      id: 2,
      text: "We leverage innovative biotechnology and sustainability breakthroughs that align with our mission of helping organisations reach ESG goals.",
      title: "Meaningful innovation",
      icon: {
        name: "streamline:industry-innovation-and-infrastructure-solid",
      },
      isLight: false,
      data: {},
    },
    {
      id: 3,
      text: "Bringing our partners and industry along on the journey is the only way we will achieve positive change at scale. Our commitment to sharing and giving is central to our success.",
      title: "Active collaboration",
      icon: {
        name: "ph:chats-circle",
      },
      isLight: false,
      data: {},
    },
    {
      id: 4,
      text: "Real change needs bravery and accountability – it’s why we invest in the tools and products that will increase the transparency of the cleaning industry’s environmental impact.",
      title: "Radical transparency",
      icon: {
        name: "bxs:happy",
      },
      isLight: false,
      data: {},
    },
  ];

  return (
    <section className="bg-main-dark-green">
      <div className="container py-14">
        <h5 className="text-h5 text-center mb-14 font-semibold">Our values</h5>
        <div className="grid max-w-[896px] mx-auto gap-8 grid-cols-1 sm:grid-cols-2">
          {data.map((item) => {
            return (
              <div className="max-w-[432px] mx-auto">
                <div className="w-[60px] h-[60px] bg-main-accent rounded-full p-3 mx-auto">
                  {item.icon && (
                    <Icon
                      icon={item.icon?.name}
                      className="w-full h-full text-black"
                    />
                  )}
                </div>
                <h4
                  className={cn(
                    "text-[22px] sm:text-[28px]  md:text-h4 py-4 font-semibold text-center"
                  )}
                >
                  {item.title}
                </h4>
                <p
                  className={cn(
                    " max-w-[358px] mx-auto sm:max-w-none  text-center"
                  )}
                >
                  {item.text}
                </p>
                {/* <MultiColumnCard
                  text={item.text}
                  title={item.title}
                  icon={item.icon}
                  data={item}
                /> */}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;
