"use client";
import { Button } from "@/components/ui/button";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { FileDownloadProps } from "@/types/TypeList";
import { HiOutlineDownload } from "react-icons/hi";
type Props = {
  data: FileDownloadProps;
};

const FileDownload = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);

  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className="container">
        <div className="px-4 py-10 sm:p-[45px] bg-[url('/bg-dl.png')] bg-cover flex flex-col sm:flex-row gap-4 rounded-3xl relative">
          <div className="absolute inset-0 bg-black opacity-25 rounded-3xl"></div>
          <div className="z-[1] flex ">
            <h3 className="text-[26px] w-full md:text-h3 text-white font-semibold flex-1 ">
              {data.heading}
            </h3>
          </div>
          <div className="flex  md:min-h-[161px] flex-1 gap-4 flex-col z-[1]">
            {data.buttonList.map((item: any, key) => {
              return (
                <div key={key} className="sm:ml-auto">
                  <Button
                    variant={item.variant}
                    onClick={() => {
                      if (item.type === "fileDownload" && item.file) {
                        return window.open(item.file, "_blank");
                      }
                      if (item.type === "url" && item.href) {
                        return window.open(item.href, "_blank");
                      }
                    }}
                    // className="flex gap-3"
                  >
                    <HiOutlineDownload className="text-xl" />
                    <p style={{ wordBreak: "break-word" }}>{item.label}</p>
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FileDownload;
