import { Button } from "@/components/ui/button";
import { getTitleByCategory } from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import Link from "next/link";
import { IoNewspaperOutline } from "react-icons/io5";

type Props = {
  data: any;
  large?: boolean;
};

const ArticleCard = (props: Props) => {
  const { data, large } = props;
  return (
    <div>
      <Link
        href={`/learn/${data.slug.current}`}
        className="flex flex-col rounded-md group overflow-hidden"
      >
        <div
          className={cn(
            `relative w-full h-full aspect-[4/3] bg-slate-400 rounded-[16px] overflow-hidden group-hover:shadow-lg transition-all cursor-pointer`
          )}
        >
          <Button
            className={cn(
              "bg-white !text-main-dark-green rounded-full !h-auto font-bold uppercase absolute pointer-events-none hover:bg-white text-[12px] sm:text-sm left-4 top-4 z-[2] transition-all hover:border-black border sm:px-4 !py-2 !px-4"
            )}
          >
            {getTitleByCategory(data.postCategory)}
          </Button>
          <Image
            src={urlForImage(data.image).format("webp").url()}
            alt={data.title}
            layout="fill"
            objectFit="cover"
            className="rounded-[16px] group-hover:scale-105 duration-700 "
          />
        </div>
        <div className="flex-1 flex flex-col py-4">
          <h2 className="text-lg leading-6 sm:leading-normal sm:text-xl font-semibold group-hover:underline transition-all text-main-dark-green mb-2">
            {data.title}
          </h2>
          <div className="text-main-dark-green mt-auto flex items-center">
            <IoNewspaperOutline className="mr-2" /> {data.estimatedReadTime}{" "}
            mins
          </div>
          {/* Add additional content or details here */}
        </div>
      </Link>
    </div>
  );
};

export default ArticleCard;
