import { Button } from "@/components/ui/button";
import {
  portableTextComponents,
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";

import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import { PortableText } from "@portabletext/react";
import Image from "next/image";
import Eden from "../Eden/Eden";
import Link from "next/link";

type Props = {
  data: any;
};

const TextImageWithOverlay = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className={cn(`container  `)}>
        <div className="bg-white overflow-hidden relative rounded-3xl flex flex-col sm:flex-row gap-8 p-4 sm:p-8 md:p-[75px]">
          <div className="absolute z-0 w-[700px] pointer-events-none left-0 bottom-0 ">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 836 675"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M347.002 0C617.13 0 836 229.709 836 512.931C836 796.152 617.13 1026 347.002 1026C123.806 1026 -355.151 675.938 -514.96 554.182C-541.68 533.774 -541.68 492.266 -514.96 471.818C-355.17 350.121 123.788 0 347.002 0Z"
                fill="url(#paint0_linear_8374_28702)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_8374_28702"
                  x1="-535.005"
                  y1="536.898"
                  x2="836.597"
                  y2="432.241"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#54BE8E" />
                  <stop offset="1" stopColor="#54BE8E" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="flex-1 flex items-center">
            <div className="relative w-full aspect-square ">
              <Image
                src={urlForImage(data.image).format("webp").url()}
                alt={data.title}
                layout="fill"
                //   objectFit="cover"
                className="rounded-[16px]"
              />
            </div>
          </div>
          <div
            className={cn(
              `text-main-dark-green flex-1 relative z-10 sm:px-4 justify-center flex flex-col`
            )}
          >
            {data.subheading && (
              <h5 className="text-[20px] md:text-h5">{data.subheading}</h5>
            )}

            <h2 className="text-[32px] sm:text-[38px] md:text-h2 font-semibold leading-tight mb-4">
              {data.heading}
            </h2>
            <div className="max-w-[548px]">
              <PortableText
                value={data.content}
                components={portableTextComponents}
              />
            </div>
            {data.addButton && (
              <div className="py-4 sm:py-8">
                <Link href={data.button.href}>
                  <Button size={"lg"} variant={data.button.variant}>
                    {data.button.label}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextImageWithOverlay;
