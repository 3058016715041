import { cn } from "@/lib/utils";
import ProductCard from "../Card/ProductCard";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
type Props = {
  data: {
    headingPosition: string;
    heading: string;
    description: string;
    listType: string;
    spacing: string;
    background: string;
    productReferenceList: [
      {
        product: {
          title: string;
          subtitle: string;
          price: number;
          slug: {
            current: string;
          };
          category: {
            slug: {
              current: string;
            };
          };
          image: {
            asset: {
              url: string;
            };
          };
          productTheme: {
            hex: string;
          };
        };
      }
    ];
  };
};

const CardListCustomProducts = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className="container pb-8">
        <h2
          className={cn(
            `text-[22px] sm:text-h4 font-semibold text-center`,
            `text-${data.headingPosition}`
          )}
        >
          {data.heading}
        </h2>
      </div>
      <div className="container transition-all grid sm:grid-cols-2 md:grid-cols-3 gap-4">
        {data &&
          data.productReferenceList.map((item: any, index: number) => {
            return <ProductCard data={item} key={index} />;
          })}
      </div>
    </section>
  );
};
export default CardListCustomProducts;
