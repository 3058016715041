"use client";
import Link from "next/link";
import CategoryCard from "../Card/CategoryCard";
import { removedCategory } from "@/lib/globalFunctions";

type Props = {
  data: any;
};

const ShopByCategory = (props: Props) => {
  const { data } = props;

  return (
    <section className="bg-[#f5f5f6] py-8">
      <div className="container ">
        <div className="py-8">
          <h2 className="text-h4 text-black font-semibold mb-8">
            Shop by Category
          </h2>
          <ul className="grid  grid-cols-2 md:grid-cols-4 gap-8">
            {data.categoryList.map((item: any, index: number) => {
              // console.log(item);
              if (removedCategory.includes(item.title)) {
                return null;
              }
              return (
                <Link key={index} href={`/products/${item.slug.current}`}>
                  <CategoryCard data={item} />
                </Link>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ShopByCategory;
