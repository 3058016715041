"use client";
import { cn } from "@/lib/utils";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import ArticleCard from "../Card/ArticleCard";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { useGlobalStore } from "@/lib/globalStore";
type Props = {
  data: any;
};

const CardListCustomArticles = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  const { getStartedOpen, setGetStartedOpen } = useGlobalStore();
  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className="container flex pb-8">
        <h2
          className={cn(
            `text-h4 font-semibold !text-black text-center`,
            `text-${data.headingPosition}`
          )}
        >
          {data.heading}
        </h2>
        {data.addButton && (
          <>
            {data.button.type === "getStarted" ? (
              <div className="ml-auto">
                <Button
                  variant={data.button.variant}
                  size={data.button.size}
                  onClick={() => setGetStartedOpen(true)}
                >
                  {data.button.label}
                </Button>
              </div>
            ) : (
              <div className="ml-auto">
                <Link href={data.button.href}>
                  <Button size={data.button.size} variant={data.button.variant}>
                    {data.button.label}
                  </Button>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      <div className="container transition-all grid sm:grid-cols-3 gap-4">
        {data &&
          data.articleReferenceList.map((item: any, index: number) => {
            return <ArticleCard data={item} key={index} />;
          })}
      </div>
    </section>
  );
};
export default CardListCustomArticles;
