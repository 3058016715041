"use client";
import { Button } from "@/components/ui/button";
import { motion, useInView, useScroll } from "framer-motion";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
type Props = {
  item: any;
};

const FeaturedContent = ({ item }: Props) => {
  let ref = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section
      ref={ref}
      id={item.gsapId}
      style={{
        backgroundImage: isMobile ? `url(${item.image})` : "none",
        backgroundSize: "cover",
      }}
      className={`px-4 sm:px-0 min-h-screen relative md:max-w-[400px] lg:max-w-[484px] flex items-center justify-center flex-col text-center content-${item.id}`}
    >
      <div className="absolute md:hidden w-full  h-full bg-black/40 left-0 top-0"></div>
      <h2 className="text-[38px]  sm:text-[42px] md:text-h2 font-semibold leading-none z-[2]">
        {item.title}
      </h2>
      <p className="text-xl px-4 sm:text-[24px] md:text-h5 font-semibold py-8 !leading-[2.25rem] z-[2]">
        {item.description}
      </p>
      <div>
        <Link href={item.url}>
          <Button size={"lg"} variant={"secondary-light"}>
            {item.buttonText}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default FeaturedContent;
