"use client";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import React, { useRef } from "react";
import Eden from "../Eden/Eden";
import { motion } from "framer-motion";
import Word from "@/components/atoms/Word";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
type Props = {
  data: any;
};

const EdenScrollParagraph = (props: Props) => {
  let { data } = props;
  let element = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element.current,
        // start: "top center",
        // end: "bottom top",
        // markers: true,
      },
    });
    tl.fromTo(
      ".word-content",
      { scale: 0, transformOrigin: "top left" },
      { scale: 1, opacity: 1, duration: 0.8, delay: 1, ease: "expo" }
    );
  }, [{ scope: element }]);
  // useEffect(() => {
  //   scrollYProgress.on("change", (e) => console.log(e));
  // }, []);
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section
      ref={element}
      className={cn(``, sectionBackground, sectionSpacing)}
    >
      <div className="container mb-10">
        <div>
          <div className=" flex gap-2  items-center">
            <div className="w-16 aspect-square">
              <Eden className="px-2  !gap-[2px] !rounded-bl-none !rounded-br-2xl sm:!rounded-bl-2xl sm:!rounded-br-none" />
            </div>
            <p
              className={cn(
                ` p-5 block rounded-lg  sm:bg-transparent `,
                data.background === "light"
                  ? "text-black"
                  : "text-white sm:text-white/50 bg-white/50"
              )}
            >
              Eden
            </p>
          </div>
          <div className="flex max-w-[1008px]">
            <div className="hidden sm:block sm:min-w-[64px]  aspect-square"></div>
            <div className="word-content text-[26px] sm:text-[36px] md:text-[45px] rounded-tl-none py-4 px-6 rounded-3xl bg-white inline-block font-semibold text-main-dark-green/50 leading-tight">
              <motion.div>
                <Word text={data.content} />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EdenScrollParagraph;
