import { Button } from "@/components/ui/button";
import {
  portableTextComponents,
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";

import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import { PortableText } from "@portabletext/react";
import Image from "next/image";
import Eden from "../Eden/Eden";
import Link from "next/link";

type Props = {
  data: any;
};

const TextImage = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);

  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className={cn(`container`)}>
        <div
          className={cn(
            "flex w-full relative md:gap-12",
            data.imagePosition === "right"
              ? "flex-col md:flex-row-reverse"
              : "flex-col md:flex-row",
            data?.capsulatedBackground ? "px-4 md:px-12 lg:px-24" : ""
          )}
        >
          {data.variant && data.capsulatedBackground && (
            <Image
              src={urlForImage(data.capsulatedBackground).format("webp").url()}
              alt={data.title}
              layout="fill"
              objectFit="cover"
              className="rounded-[24px] z-[1] brightness-80"
            />
          )}

          <div className="flex-1 z-[2] flex md:block justify-center  ">
            <div className="relative w-full sm:max-w-[50%] md:max-w-full aspect-square ">
              <Image
                src={urlForImage(data.image).format("webp").url()}
                alt={data.title}
                layout="fill"
                objectFit="cover"
                className="rounded-[24px]"
              />
            </div>
          </div>
          <div
            className={cn(
              `text-main-dark-green z-[2] flex-1 ${data.image} sm:px-4 justify-center flex flex-col`
            )}
          >
            {data.isEdenHeading && (
              <div className="flex flex-col gap-4 mb-4">
                <div className="flex gap-4">
                  <div className="w-24 h-24 aspect-square">
                    <Eden icon={"star"} iconSize={48} />
                  </div>
                  <div className="rounded-tl-[14.5px] eden-text sm:bg-white flex items-center sm:justify-center rounded-tr-[14.5px] rounded-br-[14.5px]">
                    <h3
                      className={`text-[#0C1E20]  py-4 sm:py-0 text-[38px] sm:text-[48px] md:text-h3  rounded-b-[14.5px] rounded-tr-[14.5px] bg-white sm:bg-transparent font-bold transition-all px-6`}
                    >
                      {data.heading}
                    </h3>
                  </div>
                </div>
                {data.edenSubheading && (
                  <div className="rounded-tl-[14.5px] eden-text sm:bg-white flex items-center sm:justify-center rounded-tr-[14.5px] rounded-br-[14.5px]">
                    <h3
                      className={`text-[#0C1E20]  py-4 sm:py-0 text-[38px] sm:text-[48px] md:text-h3  rounded-b-[14.5px] rounded-tr-[14.5px] bg-white sm:bg-transparent font-bold transition-all px-6`}
                    >
                      {data.edenSubheading}
                    </h3>
                  </div>
                )}
              </div>
            )}
            {!data.isEdenHeading && (
              <h2 className="text-[32px] sm:text-[38px] md:text-h2  font-bold leading-tight my-4">
                {data.heading}
              </h2>
            )}
            <div
              className={cn(
                data.background === "dark" ? "text-white" : "",
                data.variant === "capsulated"
                  ? "md:[&>h2]:text-h2 [&>h2]:font-bold [&>h2]:text-[2.25rem]"
                  : ""
              )}
            >
              <PortableText
                value={data.content}
                components={portableTextComponents}
              />
            </div>

            {data.addButton && (
              <div className="py-4 sm:py-8">
                <Link href={data.button.href || data.button.link}>
                  <Button size={"lg"} variant={data.button.variant}>
                    {data.button.label}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextImage;
