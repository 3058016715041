"use client";

import { cn } from "@/lib/utils";
import { MultiColumnProps } from "@/types/TypeList";
import MultiColumnCard from "../Card/MultiColumnCard";

type Props = {
  data: MultiColumnProps;
};

const MultiColumn = (props: Props) => {
  const { data } = props;
  // let sectionBackground = sectionBackgroundHandler(data.background);
  // let sectionSpacing = sectionSpacingHandler(data.spacing);
  const sectionBackgroundHandler = (background: any) => {
    if (background === "light") return "bg-[#F5F5F6]";
    if (background === "dark") return "bg-[#0C1E20]";
    if (background === "darkLight") return "bg-[#0C1E20]";
    if (background === "lightDark") return "bg-[#F5F5F6]";
    return "bg-[#F5F5F6]";
  };
  const sectionSpacingHandler = (spacing: any) => {
    if (spacing === "standard") return "py-[39px] sm:py-[45px]";
    if (spacing === "small") return "py-[30px]";
    if (spacing === "large") return "py-[80px]";
    return "py-[45px]";
  };
  return (
    <section
      id="multi-column"
      className={cn(
        sectionBackgroundHandler(data.background),
        sectionSpacingHandler(data.spacing)
      )}
    >
      {data.heading && (
        <h5 className="text-h5 text-center text-main-dark-green mb-8 font-semibold">
          {data.heading}
        </h5>
      )}
      <div className="container px-8 sm:py-8 transition-all grid grid-cols-1 sm:grid-cols-3 gap-4">
        {data.columnList.map((item: any, key: number) => {
          return (
            <MultiColumnCard
              isLight={data.background === "light"}
              key={key}
              icon={item.icon}
              text={item.text}
              title={item.title}
              data={item}
            />
          );
        })}
      </div>
    </section>
  );
};

export default MultiColumn;
