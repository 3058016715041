import HeaderEdensWorld from "../molecules/HeaderVariants/HeaderEdensWorld";
import HeaderMain from "../molecules/HeaderVariants/HeaderMain";
import HeaderReduced from "../molecules/HeaderVariants/HeaderReduced";
import HeaderStandard from "../molecules/HeaderVariants/HeaderStandard";
import HeaderKeynote from "../molecules/HeaderVariants/HeaderKeynote";
type Props = {
  data: any;
  index?: number;
  lastIndex?: number;
};

const Header = (props: Props) => {
  const { data, index, lastIndex } = props;
  switch (data.variant) {
    case "main":
      return <HeaderMain lastIndex={lastIndex} index={index} data={data} />;
    case "reduced":
      return <HeaderReduced lastIndex={lastIndex} index={index} data={data} />;
    case "edensWorld":
      return <HeaderEdensWorld data={data} />;
    case "standard":
      return <HeaderStandard data={data} />;
    case "keynote":
      return <HeaderKeynote data={data} />;
    default:
      return <div className="bg-red-100 py-16"></div>;
  }
};

export default Header;
