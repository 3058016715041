"use client";

import { urlForImage } from "@/sanity/lib/image";
import SearchInput from "../SearchInput/SearchInput";
import { cn } from "@/lib/utils";

type Props = {
  data: any;
};

const HeaderEdensWorld = (props: Props) => {
  const { data } = props;
  let imageExists = data?.image ? `` : `bg-[url('/header-main-bg.jpg')] `;
  return (
    <div
      className={cn({
        "bg-[#f5f5f6]": data.background === "light",
      })}
    >
      <section className={cn("sm:container", "sm:py-6")}>
        <div
          style={
            data?.image && {
              backgroundImage: `url(${urlForImage(data.image)
                .format("webp")
                .url()})`,
            }
          }
          className={`p-4 sm:p-16 bg-cover ${imageExists} rounded-b-2xl sm:rounded-2xl relative overflow-hidden`}
        >
          <div className="w-full z-[1] h-full absolute bg-black/30 left-0 top-0"></div>
          <div className="flex relative z-[2] flex-col sm:flex-row">
            <div className="max-w-[776px] mb-4 sm:mb-0">
              <p className="text-[48px] sm:text-[120px] leading-none font-semibold">
                Welcome to
              </p>
              <p className="text-[48px] sm:text-[120px] text-main-accent leading-none font-semibold">
                Eden's World
              </p>
            </div>
            <div className="sm:ml-auto mt-4 sm:mt-0">
              <div className="max-w-[314px] font-semibold text-[20px] mb-4  sm:mb-0 sm:text-[27px] ">
                <h5>
                  Make a clean change with ESG education and tips on greener
                  cleaning.
                </h5>
              </div>
            </div>
          </div>
          {/* <div className="mt-24">
            <SearchInput />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default HeaderEdensWorld;
