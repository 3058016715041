import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import { FC, useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface Props {
  data: {
    background: string;
    spacing: string;
    heading: string;
    companyListTop: any[];
    companyListBottom: any[];
  };
}

function Scroller({ children }: { children: React.ReactNode }) {
  return <div className="scroller">{children}</div>;
}

const CompanyList = ({ items }: { items: any[] }) => (
  <div className="scroller__inner">
    {items.map((item, index) => (
      <li
        key={index}
        className="aspect-square max-w-[86px] sm:max-w-[172px] flex items-center justify-center bg-white rounded-2xl"
      >
        <Image
          src={urlForImage(item).url()}
          width={500}
          height={500}
          className="w-[172px] p-4"
          alt={item.alt}
        />
      </li>
    ))}
  </div>
);

const CompanyShowcase: FC<Props> = ({ data }) => {
  const { background, spacing, heading, companyListTop, companyListBottom } =
    data;

  const sectionBackground = sectionBackgroundHandler(background);
  const sectionSpacing = sectionSpacingHandler(spacing);

  const ref = useRef<HTMLDivElement>(null);
  let allCompanyList = [...companyListTop, ...companyListBottom];
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const animateScroll = (selector: string, direction: number) => {
      gsap.to(selector, {
        scrollTrigger: {
          trigger: ref.current,
          start: "top 50%",
          end: "+=5000",
          scrub: 1,
        },
        x: direction * (window.innerWidth - 500),
      });
    };

    animateScroll(".company-list-top", -1);
    animateScroll(".company-list-bottom", 1);
  }, [ref]);

  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      scrollers.forEach((scroller) => {
        if (!scroller.getAttribute("data-animated")) {
          scroller.setAttribute("data-animated", "true");

          const scrollerInner = scroller.querySelector(".scroller__inner");
          if (scrollerInner) {
            const children = Array.from(scrollerInner.children);
            children.forEach((child) => {
              const duplicate = child.cloneNode(true) as HTMLElement;
              duplicate.setAttribute("aria-hidden", "true");
              scrollerInner.appendChild(duplicate);
            });
          }
        }
      });
    }
  }, []);

  return (
    <section ref={ref} className={cn(sectionBackground, sectionSpacing)}>
      <div>
        <h5 className="text-h5 text-black text-center font-semibold mb-14 px-4">
          {heading || "Join the movement of companies doing the right thing"}
        </h5>
      </div>

      <div>
        <Scroller>
          <div className="company-list-top">
            <CompanyList items={allCompanyList} />
          </div>
        </Scroller>
      </div>

      {/* <div>
        <Scroller>
          <div className="company-list-bottom">
            <CompanyList items={companyListBottom} />
          </div>
        </Scroller>
      </div> */}

      {/* Mobile Specific Rendering */}
      {/* <div className="block md:hidden">
        <Scroller>
          <div className="company-list-top">
            <CompanyList items={allCompanyList} />
          </div>
        </Scroller>
      </div> */}

      <div className="py-8 sm:py-12"></div>
    </section>
  );
};

export default CompanyShowcase;
