import { cn } from "@/lib/utils";
import ProductCard from "../Card/ProductCard";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import EdenCard from "../Card/EdenCard";
type Props = {
  data: {
    headingPosition: string;
    heading: string;
    description: string;
    listType: string;
    spacing: string;
    background: string;
    subheading?: string;
    edenCardList: {
      caption: string;
      myth: string;
      debunk: string;
    }[];
  };
};

const CardListEden = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className="container pb-8">
        <h2
          className={cn(
            `text-[32px] sm:text-h2 text-main-dark-green  font-semibold text-center`,
            `text-${data.headingPosition}`
          )}
        >
          {data.heading}
        </h2>
        {data.subheading && (
          <h5 className="text-[20px] sm:text-h5 text-center py-6 sm:py-10 text-main-dark-green">
            {data.subheading}
          </h5>
        )}
      </div>
      <div className="container transition-all grid sm:justify-items-center sm:grid-cols-[repeat(auto-fill,minmax(260px,1fr))] lg:grid-cols-3 gap-4">
        {data.edenCardList.map((card, index) => {
          return <EdenCard key={index} index={index} data={card} />;
        })}
      </div>
    </section>
  );
};
export default CardListEden;
