"use client";

import Eden from "@/components/molecules/Eden/Eden";
import { urlForImage } from "@/sanity/lib/image";
import { cn } from "@/lib/utils";
import gsap from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

type Props = {
  data: any;
};

const HeaderStandard = (props: Props) => {
  const { data } = props;
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useGSAP(
    () => {
      const tl = gsap.timeline({
        delay: 0.8,
        scrollTrigger: {
          trigger: ".eden-text",
        },
      });
      tl.fromTo(
        ".eden-text",
        {
          scale: 0,
          opacity: 0,
          transformOrigin: "top left",
        },
        {
          scale: 1,
          opacity: 1,
          ease: "power4.out",
        }
      ).fromTo(
        ".eden-subtext",
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        ">0.2"
      );
    },
    { scope: ref }
  );
  let imageExists = data.image
    ? ``
    : `bg-[url('/header-main-bg.jpg')] shadow-overlay`;
  return (
    <div
      ref={ref}
      className={cn("relative", {
        "bg-[#f5f5f6]": data.background === "light",
      })}
    >
      <section className="sm:container sm:py-6 ">
        <div
          style={
            data.image && {
              backgroundImage: `url(${urlForImage(data.image)
                .format("webp")
                .url()})`,
            }
          }
          className={`py-6 px-4 sm:p-16 bg-cover min-h-[400px] sm:min-h-[532px] flex flex-col relative overflow-hidden ${imageExists} rounded-b-2xl  sm:rounded-2xl`}
        >
          <div className="w-full z-[1] h-full absolute bg-black/30 left-0 top-0"></div>
          <div className="flex relative z-[2] flex-col sm:flex-row gap-2 sm:gap-4 mb-4">
            <div
              className={`w-full max-w-[66px] sm:max-w-[80px] md:max-w-[125px] h-fit aspect-square`}
            >
              <Eden />
            </div>
            <div className="rounded-tl-[14.5px] eden-text sm:bg-white flex items-center sm:justify-center rounded-tr-[14.5px] rounded-br-[14.5px]">
              <h1
                className={`text-[#0C1E20]  py-4 sm:py-0 text-[38px] sm:text-[42px] md:text-[77px]  rounded-b-[14.5px] rounded-tr-[14.5px] bg-white sm:bg-transparent font-bold transition-all px-6`}
              >
                {data.edenText}
              </h1>
            </div>
          </div>

          {data.subtext && (
            <div className="max-w-[659px] relative z-[2] eden-subtext hidden sm:block mt-auto font-semibold text-[20px] md:text-[27px] ">
              <h5 className="mt-16">{data.subtext}</h5>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default HeaderStandard;
