import { Button } from "@/components/ui/button";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import Link from "next/link";
import { FaExternalLinkAlt } from "react-icons/fa";

type Props = {
  data: any;
};

const ProductFeature = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div
        className={cn(
          `container flex flex-col sm:flex-row sm:gap-8`,
          data.imagePosition === "right" && "flex-col sm:flex-row-reverse"
        )}
      >
        <div className="flex-1">
          <div
            className=" bg-white w-full rounded-2xl aspect-square"
            style={{
              background: data.featuredProduct.productTheme
                ? `linear-gradient(to bottom, #fff, #fff, rgba(${data.featuredProduct.productTheme.rgb.r}, ${data.featuredProduct.productTheme.rgb.g}, ${data.featuredProduct.productTheme.rgb.b}, 0.5))`
                : "linear-gradient(to bottom, #fff, #fff, #fff)",
            }}
          >
            <div className="relative w-full aspect-square ">
              <Image
                src={urlForImage(data.featuredProduct.image)
                  .format("webp")
                  .url()}
                alt={data.title}
                layout="fill"
                objectFit="contain"
                className="p-16"
              />
            </div>
          </div>
        </div>
        <div
          className={cn(
            `text-main-dark-green flex-1 ${data.featuredProduct.image} px-4 justify-center flex flex-col`
          )}
        >
          <div className="sm:max-w-[432px] mx-auto">
            <div className="py-8">
              <h2 className="text-[32px] md:text-h2 font-semibold leading-tight mb-4">
                {data.featuredProduct.title}
              </h2>
              <p className="font-semibold text-subtitle text-[#525f60] mb-2">
                {data.featuredProduct.subtitle}
              </p>
              {data.featuredProduct.price !== 0 && (
                <p className="text-subtitle font-semibold py-4">
                  ${data.featuredProduct.price.toFixed(2)}{" "}
                  <span className="text-sm text-black/75">excl. gst</span>
                </p>
              )}

              <p className="text-body line-clamp-5 md:line-clamp-none">
                {data.description}
              </p>
            </div>
            <div className="flex gap-8">
              <Link
                href={`/products/${data.featuredProduct.category.slug.current}/${data.featuredProduct.slug.current}`}
              >
                <Button variant={"secondary"}>See details</Button>
              </Link>
              {data.featuredProduct.link && !data.isHiddenBuyNow && (
                <Link
                  href={data.featuredProduct.link}
                  target="_blank"
                  // rel="noopener noreferrer"
                >
                  <Button
                    variant={"secondary"}
                    className="flex gap-2 font-semibold bg-transparent border-transparent "
                  >
                    Buy now <FaExternalLinkAlt />
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductFeature;
