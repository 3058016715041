"use client";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import Link from "next/link";
import React from "react";
import { FaArrowRight } from "react-icons/fa6";

type Props = {
  data: any;
};

const ShortCTA = (props: Props) => {
  const { data } = props;
  const arrow = {
    initial: { x: 0 },
    animate: { x: 10 },
    transition: { type: "spring", duration: 0.6, stiffness: 100, damping: 10 },
  };
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(``, sectionBackground, sectionSpacing)}>
      <div className="container overflow-hidden text-black ">
        <div className="bg-[#051F20] relative rounded-2xl sm:rounded-3xl py-10 px-4 sm:p-10 md:p-20">
          <div className="absolute z-1 bottom-0 max-w-[200px] sm:max-w-[664px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1135"
              height="216"
              viewBox="0 0 1135 216"
              fill="none"
            >
              <path
                d="M730.176 0.129883C953.805 0.129883 1135 181.032 1135 404.075C1135 627.119 953.805 808.13 730.176 808.13C545.401 808.13 148.89 532.448 16.5901 436.562C-5.53002 420.49 -5.53002 387.801 16.5901 371.698C148.875 275.859 545.386 0.129883 730.176 0.129883Z"
                fill="url(#paint0_linear_7888_20761)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_7888_20761"
                  x1="-0.00379934"
                  y1="422.95"
                  x2="1134.8"
                  y2="331.926"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B4F9D3" stopOpacity="0.4" />
                  <stop offset="1" stopColor="#B4F9D3" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <motion.div
            className="z-10 relative"
            initial="initial"
            animate="initial"
            whileHover="animate"
          >
            <Link href={data.link} className="flex gap-6 items-center ">
              <h2 className="text-[26px] max-w-[248px] sm:max-w-none sm:ml-auto text-white sm:text-h3 font-bold md:text-left">
                {data.heading}
              </h2>
              <div className="sm:w-[90px] ml-auto sm:ml-0 min-w-[54px] aspect-square ">
                <motion.div
                  variants={arrow}
                  className="w-full h-full  bg-main-accent rounded-2xl"
                >
                  <FaArrowRight className="text-black w-full h-full p-4" />
                </motion.div>
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ShortCTA;
