"use client";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import useWindowSize from "@/hooks/useWindowSize";
import { styled } from "styled-components";
type Props = { data: any };

const ProductCard = (props: Props) => {
  const { data } = props;
  const [isHovered, setIsHovered] = useState(false);
  const windowSize = useWindowSize();
  return (
    <Link
      href={`/products/${data.category.slug.current}/${data.slug.current}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={cn(
        " flex flex-col group overflow-hidden transition-all relative p-6 rounded-2xl bg-opacity-5 text-main-dark-green"
      )}
      style={{
        background: "white",
      }}
      // style={{
      //   background: "white",
      //   // background:
      //   //   isHovered || windowSize.width < 687
      //   //     ? `linear-gradient(to bottom, #fff, #fff, rgba(${data.productTheme.rgb.r}, ${data.productTheme.rgb.g}, ${data.productTheme.rgb.b}, 0.5))`
      //   //     : "linear-gradient(to bottom, #fff, #fff, #fff)",
      // }}
    >
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, #fff,#fff)`,
          // transition: "opacity 0.3s linear",
          zIndex: "1",
        }}
        className="absolute transition-opacity left-0 top-0 w-full h-full opacity-100 group-hover:opacity-0 group-hover:z-[0] "
      ></div>
      <div className="flex-1 flex flex-col z-[2]">
        <div>
          <div className="flex justify-between">
            <h4 className="text-xl font-semibold transition-all ">
              {data.title}
            </h4>
            {data.price !== 0 && (
              <p className="block text-right leading-none sm:hidden">
                ${data.price.toFixed(2)} <br />
                <span className="text-[12px]  text-black/75">excl. gst</span>
              </p>
            )}
          </div>
          <p className="font-semibold text-[#506363] max-w-[50%] sm:max-w-full">
            {data.subtitle}
          </p>
          {data.price !== 0 && (
            <p className="hidden sm:block">
              ${data.price.toFixed(2)}{" "}
              <span className="text-[12px] text-black/75">excl. gst</span>
            </p>
          )}
        </div>
        <div className="relative w-full h-52  transition-all ">
          <Image
            src={urlForImage(data.image).format("webp").url()}
            alt={data.title}
            layout="fill"
            objectFit="contain"
            className="rounded-md"
          />
        </div>
        <div
          style={{ opacity: windowSize.width < 687 ? "100" : "" }}
          className="group-hover:opacity-100 group-hover:translate-y-0 opacity-0 translate-y-4 transition-all text-center"
        >
          <p className="font-semibold">See details</p>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, #fff, #fff, rgba(${data.productTheme.rgb.r}, ${data.productTheme.rgb.g}, ${data.productTheme.rgb.b}, 0.5))`,
          // transition: "opacity 0.3s linear",
          zIndex: "1",
        }}
        className="absolute  transition-opacity duration-300 left-0 top-0 w-full h-full opacity-0 group-hover:opacity-100 group-hover:z-[0]  "
      ></div>
    </Link>
  );
};

export default ProductCard;
