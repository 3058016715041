import { Icon } from "@iconify/react/dist/iconify.js";

type Props = {
  data: {
    description?: string;
    icon?: any;
    slug: string;
    title: string;
  };
};

const CategoryCard = (props: Props) => {
  const { data } = props;
  return (
    <div className="w-full group aspect-square border-2 border-transparent transition-all hover:border-main-accent flex flex-col bg-white rounded-2xl p-6">
      <div className="w-full aspect-square lg:p-8">
        {data.icon ? (
          <Icon
            icon={data.icon.name}
            className="w-full h-full sm:group-hover:text-[#31EB97]/100 transition-all text-[#31EB97] sm:text-[#31EB97]/50"
          />
        ) : (
          <Icon
            icon={"mdi:kitchen-counter"}
            className="w-full h-full group-hover:text-[#31EB97]/100 transition-all text-[#31EB97] sm:text-[#31EB97]/50"
          />
        )}
      </div>
      <div className="mt-auto">
        <p className="text-subtitle text-center  text-main-dark-green font-semibold">
          {data.title}
        </p>
      </div>
    </div>
  );
};

export default CategoryCard;
