"use client";
import { Button } from "@/components/ui/button";
import Eden from "../Eden/Eden";
import { FaArrowTurnUp } from "react-icons/fa6";
import { useState } from "react";
import { cn } from "@/lib/utils";
type Props = {
  index: number;
  data: {
    caption: string;
    myth: string;
    debunk: string;
    debunkHover?: string;
    mythHover?: string;
  };
};

const EdenCard = (props: Props) => {
  const { data, index } = props;
  const [isFlipped, setIsFlipped] = useState(false);
  function handleFlip() {
    setIsFlipped(!isFlipped);
  }
  return (
    <div
      onClick={handleFlip}
      className={cn(
        `rounded-2xl eden-card group min-h-[415px]  sm:min-h-[550px] cursor-pointer p-6 sm:p-10 bg-[#ecfeee] sm:bg-white hover:bg-[#ecfeee] border hover:border-1 border-main-accent sm:border-transparent hover:border-main-accent transition-all`,
        isFlipped && `cardFlip`
      )}
    >
      <div className="front h-full flex flex-col">
        <div className="flex justify-center">
          <Button className="rounded-full bg-[#051F20] uppercase px-10 text-tag">
            {data.caption ? data.caption : `Cleaning Myth #${index + 1}`}
          </Button>
        </div>
        <h4 className="text-main-dark-green font-semibold text-center text-[22px] md:text-[1.8rem] lg:text-h4 py-14">
          "{data.myth}"
        </h4>
        <div className="text-main-dark-green sm:opacity-0 sm:group-hover:opacity-100 transition-all mt-auto flex items-center gap-4">
          <div className="w-9 h-9">
            <Eden className="rounded-[4px]" icon="star" />
          </div>
          <p className="text-subtitle font-semibold">
            <span className="hidden sm:block">
              {data.mythHover ? data.mythHover : "Debunk Myth"}
            </span>
            <span className="block sm:hidden">
              {data.mythHover ? data.mythHover : "Tap to debunk"}
            </span>
          </p>
          <FaArrowTurnUp className="ml-auto" size={24} />
        </div>
      </div>

      <div className="back flex bg-main-dark-green rounded-2xl flex-col w-full left-0 h-full p-6 sm:p-10 absolute top-0 ">
        <div className="flex justify-center">
          <Button className="rounded-full bg-main-accent hover:bg-main-accent/75 uppercase px-10 !text-tag">
            <span className="text-main-dark-green font-semibold">
              {data.caption ? data.caption : `Cleaning Myth #${index + 1}`}
            </span>
          </Button>
        </div>
        <div className="pt-6 sm:pt-14 pb-4">
          <p className="text-main-dark-green bg-white p-6 font-semibold text-base sm:text-subtitle rounded-2xl rounded-bl-none ">
            "{data.debunk}"
          </p>
        </div>
        <div className="flex gap-4 items-center">
          <div className="w-12 h-12">
            <Eden className="rounded-[4px]" icon="star" />
          </div>
          <p>Eden</p>
        </div>
        <div className="text-white  sm:opacity-0 sm:group-hover:opacity-100 transition-all mt-auto flex items-center gap-4">
          <p className="text-[20px] sm:text-subtitle font-semibold">
            <span className="hidden sm:block">
              {data.debunkHover ? data.debunkHover : "Show Myth"}
            </span>
            <span className="block sm:hidden">
              {data.debunkHover ? data.debunkHover : "Tap to show myth"}
            </span>
          </p>
          <FaArrowTurnUp className="ml-auto" size={24} />
        </div>
      </div>
    </div>
  );
};

export default EdenCard;
