"use client";
import {
  portableTextComponents,
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { PortableText } from "@portabletext/react";

type Props = {
  data: any;
};
const TextSection = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(sectionBackground, sectionSpacing)}>
      <div className="container text-black ">
        <div className="">
          <PortableText
            value={data.content}
            components={portableTextComponents}
          />{" "}
        </div>
      </div>
    </section>
  );
};

export default TextSection;
