import { Button } from "@/components/ui/button";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import Link from "next/link";
import React from "react";

type Props = {
  data: any;
};

const FeaturedCta = (props: Props) => {
  const { data } = props;
  let imageExists = data.image ? `` : `bg-[url('/header-main-bg.jpg')] `;
  const divProps = {
    style: data.image && {
      backgroundImage: `url(${urlForImage(data.image).format("webp").url()})`,
    },
    className: `${
      data.variant == "centered" ? "flex justify-center items-center" : ""
    } shadow-overlay text-black sm:min-h-[710px] px-4 py-10 sm:p-[92px] bg-cover ${imageExists} rounded-2xl`,
  };
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);

  const renderVariant = () => {
    switch (data.variant) {
      case "imageText":
        return (
          <div className="flex flex-col md:flex-row items-center gap-4 text-white">
            <div className="relative max-w-[238px] sm:max-w-[300px] md:max-w-none flex-1 w-full aspect-square">
              {data.contentImage && (
                <Image
                  src={urlForImage(data.contentImage).format("webp").url()}
                  alt={data.title}
                  layout="fill"
                  objectFit="cover"
                  className="rounded-[16px] max-w-[500px] mx-auto"
                />
              )}
            </div>
            <div className="flex-1">
              <h2 className="text-[32px] sm:text-[38px] md:text-h2 font-semibold  max-w-[429px] leading-tight ">
                {data.heading}
              </h2>
              <p className="text-[18px] sm:text-subtitle font-semibold  max-w-[429px] my-8 mr-auto">
                {data.description}
              </p>
              {data.addButton && (
                <Link href={data.button.href}>
                  <Button size={"lg"} variant={data.button.variant}>
                    {data.button.label}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        );
      case "centered":
        return (
          <div className="gap-4 text-center text-white">
            <div className="flex  flex-col">
              <h2 className="text-[28px] font-bold  text-main-accent leading-tight ">
                {data.heading}
              </h2>
              <h3
                style={{
                  lineHeight: "normal",
                }}
                className="text-[26px] sm:text-[38px] md:text-h3 font-bold max-w-[897px] my-8 mr-auto"
              >
                {data.description}
              </h3>
              {data.addButton && (
                <Link href={data.button.href}>
                  <Button
                    size={"lg"}
                    className="backdrop-blur py-6 px-10 bg-transparent border border-white text-white hover:text-black"
                    variant={data.button.variant}
                  >
                    {data.button.label}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        );
      default:
        return <p>Default</p>;
    }
  };
  return (
    <section className={cn(``, sectionBackground, sectionSpacing)}>
      <div className="sm:container ">
        <div {...divProps}>{renderVariant()}</div>
      </div>
    </section>
  );
};

export default FeaturedCta;
