"use client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { PortableText } from "@portabletext/react";

type Props = {
  data: any;
};

const AccordionSection = (props: Props) => {
  const { data } = props;
  return (
    <section className="text-main-dark-green">
      <div className="container py-16">
        <h2 className="text-h4 font-semibold text-center">{data.heading}</h2>
        <div className="py-12">
          {data.itemList.map((item: any, index: number) => {
            return (
              <div className="py-7" key={index}>
                {item.label && (
                  <h2 className="mb-4 uppercase font-bold text-black/40">
                    {item.label}
                  </h2>
                )}

                <Accordion
                  type="single"
                  collapsible
                  className="w-full gap-4 flex flex-col"
                >
                  {item.accordionList.map((accordion: any, index: number) => {
                    return (
                      <AccordionItem
                        key={index}
                        className="border rounded-[41px] px-8 py-3"
                        value={`item-${index}`}
                      >
                        <AccordionTrigger className="text-left text-h5 py-0 font-semibold">
                          {accordion.title}
                        </AccordionTrigger>
                        <AccordionContent className="py-4">
                          <PortableText value={accordion.content} />
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AccordionSection;
