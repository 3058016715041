"use client";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import React, { useRef, useState } from "react";
import Eden from "../Eden/Eden";
import { motion } from "framer-motion";
import Word from "@/components/atoms/Word";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Button } from "@/components/ui/button";
import { FaVideo } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
type Props = {
  data: any;
};

const HeaderKeynote = (props: Props) => {
  let { data } = props;
  const [isOpen, setOpen] = useState(false);
  let element = useRef(null);
  let videoElement = useRef(null);
  let edenElement = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element.current,
      },
    });
    tl.fromTo(
      ".word-content-1",
      { scale: 0, transformOrigin: "top left" },
      { scale: 1, opacity: 1, duration: 0.8, delay: 1, ease: "expo" }
    );
    tl.fromTo(
      ".word-content-2",
      { scale: 0, transformOrigin: "top left" },
      { scale: 1, opacity: 1, duration: 0.8, ease: "expo" }
    );

    const vtl = gsap.timeline({
      scrollTrigger: {
        trigger: videoElement.current,
        start: "top center",
        end: "bottom center",
        // markers: true,
        scrub: 1,
      },
    });
    vtl.fromTo(
      videoElement.current,
      { scale: 1 },
      { scale: 1.1, duration: 1, ease: "expo.inOut" }
    );

    const edenTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: edenElement.current,
        start: "20% top",
        end: "70% top",
        // markers: true,
        scrub: 1,
      },
    });
    edenTimeline.fromTo(
      edenElement.current,
      { opacity: 1 },
      { opacity: 0, duration: 1, ease: "expo.inOut" }
    );
  }, [{ scope: [element, videoElement, edenElement] }]);

  // useEffect(() => {
  //   scrollYProgress.on("change", (e) => console.log(e));
  // }, []);
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section
      ref={element}
      className={cn(``, sectionBackground, sectionSpacing)}
    >
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        allowFullScreen={true}
        videoId="Cw6YCEHp8uU"
        onClose={() => setOpen(false)}
      />
      <div className="container mb-10">
        {/* Eden */}
        <div ref={edenElement} className="sticky max-w-[976px]  top-[7.5rem]">
          <div className=" flex gap-2  items-center">
            <div className="w-16 md:w-[96px] aspect-square">
              <Eden className="px-2 !gap-[2px]  md:!gap-[6px] !rounded-bl-none !rounded-br-2xl sm:!rounded-bl-2xl sm:!rounded-br-none" />
            </div>
            <p
              className={cn(
                ` p-5 block rounded-lg  sm:bg-transparent `,
                data.background === "light"
                  ? "text-black"
                  : "text-white sm:text-white/50 bg-white/50"
              )}
            >
              Eden
            </p>
          </div>
          <div className="flex max-w-[1008px] mb-4">
            <div className="hidden sm:block sm:min-w-[95px]  aspect-square"></div>
            <div className="flex flex-col gap-4">
              <div>
                <div className="word-content-1 text-[26px] sm:text-[36px] md:text-[59px] rounded-tl-none py-4 px-6 rounded-3xl bg-[#1E3536] inline-block font-semibold text-white leading-tight">
                  <motion.div>
                    {/* <Word text={data.content} /> */}
                    So you missed the <br /> Clean Change Conference…
                  </motion.div>
                </div>
              </div>

              <div className="word-content-2 text-[26px] sm:text-[36px] md:text-[59px] rounded-tl-none py-4 px-6 rounded-3xl  inline-block font-semibold bg-[#1E3536] text-white leading-tight">
                <motion.div>
                  {/* <Word text={data.content} /> */}
                  You can still watch my keynote.
                </motion.div>
              </div>
              <div className="mt-6 md:mt-14">
                <h5 className="text-subtitle md:text-h5 text-white/50">
                  Be a business leader who takes environmental responsibility
                  seriously with easy ESG wins.
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* Eden */}
        <div
          ref={videoElement}
          className="video-section  flex justify-center pt-20"
        >
          <div className="relative">
            <video
              className="videoTag rounded-3xl overflow-hidden "
              autoPlay
              loop
              muted
            >
              <source src={"/keynote-bg.mp4"} type="video/mp4" />
            </video>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <Button
                onClick={() => setOpen(true)}
                size={"lg"}
                variant={"primary-light"}
              >
                <FaVideo size={24} className="mr-4" /> Watch Keynote
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderKeynote;
