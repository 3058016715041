"use client";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import React from "react";
import ArticleCard from "../Card/ArticleCard";
import Link from "next/link";
import { Button } from "@/components/ui/button";

type Props = {
  data: any;
};

const GridPost = (props: Props) => {
  const { data } = props;
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section className={cn(``, sectionBackground, sectionSpacing)}>
      <div className="container text-black">
        <div className="hidden sm:grid  grid-cols-2 sm:grid-cols-6 sm:gap-8">
          {data.postList.map((item: any, index: number) => {
            if (index < 2) {
              return (
                <div key={index} className=" col-span-3">
                  <ArticleCard large data={item} />
                </div>
              );
            }
            return (
              <div key={index} className=" col-span-2 aspect-square">
                <ArticleCard data={item} />
              </div>
            );
          })}
        </div>
        <div className="sm:hidden grid gap-2 grid-cols-4 sm:grid-cols-6 sm:gap-8">
          {data.postList.map((item: any, index: number) => {
            if (index < 2) {
              return (
                <div key={index} className=" col-span-4">
                  <ArticleCard large data={item} />
                </div>
              );
            }
            return (
              <div key={index} className=" col-span-2 aspect-square">
                <ArticleCard data={item} />
              </div>
            );
          })}
        </div>

        <div className="text-center pt-4 mt-8">
          {data.addButton && (
            <Link href={data.button.href} className="mx-auto">
              <Button size={data.button.size} variant={data.button.variant}>
                {data.button.label}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default GridPost;
